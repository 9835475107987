import { useState, useRef, useEffect } from 'react'
import Link from 'next/link'
import nextConfig from 'next/config'
import { useDispatch, useSelector } from 'react-redux'
import SignInLink from '@/components/common/sign-in-link'
import styleConfig from '@/styles/style-config'
import {
  disableBodyScroll,
  enableBodyScroll,
  disableBodyScrollNav,
  enableBodyScrollNav,
  showContentOverlay,
  getCountrySelectorData,
  hideContentOverlay,
  addForwardSlash,
} from '@/lib/helpers'
import { useRouter } from 'next/router'
import { userSignOut } from '@/redux/actions/userAction'
import { prepareCollectionData, prepareCollectionDataParallel } from '@/lib/product-helper'
import { updateLoginModal } from '@/redux/actions/uiAction'
import countryList from '@/config/countries'
import CountrySelectorCurrent from '@/components/country-selector/country-selector-current'
import CountrySelectorOptions from '@/components/country-selector/country-selector-options'
import HelloBar from '@/components/common/hello-bar'
import {
  getSuggestions,
  getProductRecommendation,
  getSearchSpringData,
  getStaticSSuggestions,
} from '@/lib/search-spring'
import { trans } from '@/lib/locale-helper'
import useDebounce from '@/hooks/useDebounce'
import { getSearchSpringKeys } from '@/lib/product-helper'
import regions from '@/config/regions'
import { getHellobar } from '@/lib/contentful/hellobar'
import { getRegionNumber } from '@/lib/region-helper'
import useOutsideAlerter from '@/hooks/useOutsideAlerter'
import { siteSearchGTM, navClickGTM } from '@/lib/gtm'
import { MIN_SCROLL_Y } from '@/lib/constants'
import ScrollableHeader from './scrollable-header'

const { publicRuntimeConfig } = nextConfig()

const Header = (props) => {
  const dispatch = useDispatch()
  const [isOpened, setIsOpened] = useState(false)
  const { isAuthenticated } = useSelector((state) => state.userReducer)
  const { data: allList } = useSelector((state) => state.wishlistReducer)
  const { total_item } = useSelector((state) => state.cartReducer)
  const { cartModal } = useSelector((state) => state.uiReducer)
  const { bgOverlay } = useSelector((state) => state.productReducer)
  const [isSearchOpened, setIsSearchOpened] = useState(false)
  const [authenticateCheck, setAuthenticateCheck] = useState(null)
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const router = useRouter()
  const [showSearchBox, setShowSearchBox] = useState(router.asPath)
  const [isSearchResult, setIsSearchResult] = useState(null)
  const [searchSuggestion, setSearchSuggestion] = useState(null)
  const [alternateSearchSuggestion, setAlternateSearchSuggestion] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [popularSearches, setPopularSearches] = useState(null)
  const [topSellerData, setTopSellerData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [emptySearchResult, setEmptySearchResult] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  const searchInput = useRef()
  const mobileMenuActive = useRef(false)
  const searchMenuActive = useRef(false)
  const searchInputField = useRef(false)
  const searchRef = useRef(null)
  const searchRefSticky = useRef(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const base_path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => base_path.includes(item.domain))

  const hellobar = getHellobar()

  const proRecUrl = new URL(getSearchSpringKeys().NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL)
  proRecUrl.searchParams.append('tags', 'customers-also-viewed')
  proRecUrl.searchParams.append('bgfilter.ss_in_stock', 1)
  proRecUrl.searchParams.append('bgfilter.is_retired', false)
  proRecUrl.searchParams.append('limits', '6')

  const searchUrl = new URL(getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL)
  searchUrl.searchParams.append('bgfilter.ss_in_stock', 1)
  searchUrl.searchParams.append('bgfilter.is_retired', false)
  searchUrl.searchParams.append('redirectResponse', 'full')
  searchUrl.searchParams.append('resultsPerPage', '6')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    let topScreenLimit
    if (window.innerWidth < 453) {
      topScreenLimit = 40
    } else if (window.innerWidth < 1024) {
      topScreenLimit = 30
    } else {
      topScreenLimit = 70
    }

    // Do not display fixed header when user scrolls up at very bottom of page.
    // Fix for browsers on mobile devices.
    let documentHeight = document.body.scrollHeight
    let currentScroll = window.scrollY + window.innerHeight
    let modifier = 50
    if (currentScroll + modifier < documentHeight) {
      if (currentScrollPos <= topScreenLimit) {
        document.querySelector('.header--sticky').classList.remove('header-on-scroll')
        document.querySelector('.topbar-placeholder-div').classList.remove('placeholder-height')
      } else if (currentScrollPos <= MIN_SCROLL_Y || currentScrollPos > prevScrollPos) {
        document.querySelector('.header--sticky').classList.remove('header-on-scroll')
        document.querySelector('.topbar-placeholder-div').classList.remove('placeholder-height')
      } else if (currentScrollPos < prevScrollPos) {
        document.querySelector('.header--sticky').classList.add('show')
        document.querySelector('.header--sticky').classList.add('header-on-scroll')
        document.querySelector('.topbar-placeholder-div').classList.add('placeholder-height')
      }
    }

    setPrevScrollPos(currentScrollPos)
    return currentScrollPos
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearchChange(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm])

  const handleSearchChange = async (value) => {
    setInputValue(value)
    const queryLength = value.length
    if (queryLength > 3) {
      const finalData = await getSearchResult(value)
      setSearchData(finalData)
      const data = await getSuggestions({ query: value })
      if (data != undefined) {
        setSearchSuggestion(data.suggested)
        setAlternateSearchSuggestion(data.alternatives)
        setIsSearchResult(true)
      }
    } else {
      setEmptySearchResult(false)
      setIsSearchResult(false)
    }
  }

  const enableBodyScrollOnDesktop = () => {
    const inDesktop = window.matchMedia(`(min-width: ${styleConfig.theme.screens.md})`)

    if (inDesktop.matches) {
      //check if mobile menu if opened
      if (mobileMenuActive.current) {
        enableBodyScroll()
      }
    } else {
      if (mobileMenuActive.current) {
        disableBodyScroll()
      }
    }
  }

  const toggleSearchBox = (close = false) => {
    if (!isSearchOpened || close == true) {
      props.setShowOptions && props.setShowOptions(false)
      setIsSearchOpened(!isSearchOpened)
      props.setHideSearchOnOverlay(false)
      searchMenuActive.current = !isSearchOpened
      if (searchMenuActive.current) {
        getTrendingSearchResult()
        getTopSellers()
        showContentOverlay()
        disableBodyScrollNav()
      } else {
        hideContentOverlay()
        enableBodyScrollNav()
        setSearchSuggestion(null)
        setAlternateSearchSuggestion(null)
        setIsSearchResult(false)
        setInputValue('')
        setEmptySearchResult(false)
      }
    }
  }
  if (showSearchBox != router.asPath) {
    if (isSearchOpened == true) {
      toggleSearchBox()
    }
    setShowSearchBox(router.asPath)
  }

  const handleCountrySelectorClick = (e) => {
    const parent = e.target.parentElement
    const elem = e.target

    let btn = null

    let content = null

    if (parent == null) {
      return
    }

    if (elem.classList.contains('country-selector-btn')) {
      btn = elem
    } else if (parent.classList.contains('country-selector-btn')) {
      btn = parent
    }

    if (btn != null) {
      if (btn.classList.contains('active') == true) {
        setShowCountrySelector(true)
        showContentOverlay()
      } else {
        setShowCountrySelector(false)
        hideContentOverlay()
      }
    } else {
      if (elem.classList.contains('country-selector-content')) {
        content = elem
      } else if (parent.classList.contains('country-selector-content')) {
        content = parent
      }

      if (content == null && !isSearchOpened) {
        setShowCountrySelector(false)
        hideContentOverlay()
      }
    }
  }

  const getTrendingSearchResult = async () => {
    const searchData = getStaticSSuggestions(country.localePrefix)
    setPopularSearches(searchData.trending.queries)
  }

  const getTopSellers = async () => {
    const searchData = await getProductRecommendation(proRecUrl)
    let results = []
    let resultIds = searchData[0].results?.map((item) => item?.id)
    let topSellerDataIds = topSellerData?.map((item) => item?.id)

    if (topSellerData?.length === 0 || resultIds.join(',') !== topSellerDataIds.join(',')) {
      results = await prepareCollectionDataParallel(searchData[0].results)

      const finalData = []

      results.map((item) => {
        let product = { ...item }
        let salePrice = item.selectedVariant.salePrice
        let price = item.selectedVariant.price
        let imageUrl = item.selectedVariant.imageUrl
        product.category = item.category
        product.name = item.name
        product.imageUrl = item.imageRecUrl || imageUrl
        product.price = salePrice != 0 ? salePrice : price
        product.slug = item.slug
        finalData.push(product)
      })
      setTopSellerData(finalData)
    }
  }

  const enterPressed = async (event) => {
    const queryLength = event.target.value.length
    if (queryLength > 1 && event.key === 'Enter') {
      siteSearchGTM(event.target.value)
      if (searchUrl.searchParams.has('q')) {
        searchUrl.searchParams.set('q', event.target.value)
      } else {
        searchUrl.searchParams.append('q', event.target.value)
      }
      const searchData = await getSearchSpringData(searchUrl.href)

      toggleCloseSearch()

      if (searchData?.merchandising?.redirect != '') {
        window.location = searchData?.merchandising?.redirect
      } else {
        router.push(`/search?q=${event.target.value}`)
      }

      closeMenu()
    }
  }

  const clearAllData = (url) => {
    setSearchSuggestion(null)
    setAlternateSearchSuggestion(null)
    setIsSearchResult(false)
    setInputValue('')
    setIsOpened(false)
    setIsSearchOpened(false)
    hideContentOverlay()

    let newUrl = url ? addForwardSlash(url) : url

    router.push(newUrl)
  }

  const handleMenuClick = () => {
    closeMenu()
    sessionStorage.setItem('customCloseMenu', false)
  }

  const clearText = () => {
    setSearchTerm('')
    setEmptySearchResult(false)
    setIsSearchResult(false)
  }

  const toggleCloseSearch = () => {
    setSearchTerm('')
    toggleSearchBox(true)
  }

  const triggerSearch = async () => {
    let ele = document.getElementById('main-search-input')
    if (ele.value.length > 3) {
      props.initiateSearch(ele.value)
    } else {
      // console.log("Query string too short.")
    }
  }

  const openWishlistPage = async (isAuthenticated) => {
    if (isAuthenticated) {
      navClickGTM('/account/wishlist', 'Wishlist', 'wishlist', 'header')
      router.push('/account/wishlist')
    } else {
      navClickGTM('', 'Wishlist', 'wishlist', 'header')
      await dispatch(updateLoginModal(true))
    }
  }

  const getSearchResult = async (value) => {
    if (searchUrl.searchParams.has('q')) {
      searchUrl.searchParams.set('q', value)
    } else {
      searchUrl.searchParams.append('q', value)
    }
    const searchData = await getSearchSpringData(searchUrl.href)
    let formatResults = await prepareCollectionDataParallel(searchData.results)
    const results = formatResults
    const finalData = []
    if (results.length == 0) {
      setEmptySearchResult(true)
      return finalData
    } else {
      setEmptySearchResult(false)
    }
    results.map((item) => {
      let product = { ...item }
      let salePrice = item.selectedVariant.salePrice
      let price = item.selectedVariant.price
      let imageUrl = item.selectedVariant.imageUrl
      product.category = item.category
      product.name = item.name
      product.imageUrl = item.imageRecUrl || imageUrl
      product.price = salePrice != 0 ? salePrice : price
      product.slug = item.slug
      finalData.push(product)
    })
    return finalData
  }

  const handleCountrySelectorModal = (visitedCountry, currentCountryData) => {
    props.showCountryModal(visitedCountry, currentCountryData)
  }

  useEffect(() => {
    window.addEventListener('resize', enableBodyScrollOnDesktop)
    return () => {
      window.removeEventListener('resize', enableBodyScrollOnDesktop)
    }
  }, [])

  useEffect(() => {
    if (typeof window != undefined) {
      setIsMobile(window.innerWidth >= 1024 ? false : true)
      window.addEventListener('resize', function (event) {
        setIsMobile(window.innerWidth >= 1024 ? false : true)
      })
      setAuthenticateCheck(isAuthenticated)
    }
  }, [isAuthenticated])

  useEffect(() => {
    document.addEventListener('mousedown', handleCountrySelectorClick)
    return () => {
      document.removeEventListener('mousedown', handleCountrySelectorClick)
    }
  }, [])

  useEffect(() => {
    if (isOpened) {
      disableBodyScrollNav()
      setIsSearchOpened(false)
      hideContentOverlay()
      setSearchSuggestion(null)
      setAlternateSearchSuggestion(null)
      setIsSearchResult(false)
      setInputValue('')
    } else {
      if (!cartModal && !isSearchOpened) {
        enableBodyScroll()
      }
    }

    mobileMenuActive.current = isOpened
  }, [isOpened])

  useEffect(() => {
    if (cartModal) {
      setIsOpened(false)
      setIsSearchOpened(false)
      hideContentOverlay()
      enableBodyScrollNav()
      setSearchSuggestion(null)
      setAlternateSearchSuggestion(null)
      setIsSearchResult(false)
      setInputValue('')
    } else {
      enableBodyScroll()
    }
  }, [cartModal])

  const onSignOut = async () => {
    try {
      const savedCountry = getCountrySelectorData()
      dispatch(userSignOut())
        .then((data) => {
          window.location = `${process.env.NEXT_PUBLIC_BASE_URL}/${savedCountry?.prefix}/home`
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (err) {
      // Handle Error Here
      console.log('onSignOut errObj', err)
    }
  }

  const closeMenu = () => {
    props.setShowOptions && props.setShowOptions(false)
    hideContentOverlay()
    setShowMegaMenu(false)
  }

  useEffect(
    (showMegaMenu) => {
      if (!showMegaMenu) {
        setSearchSuggestion(null)
        setAlternateSearchSuggestion(null)
        setIsSearchResult(false)
        setInputValue('')
      }
    },
    [showMegaMenu]
  )

  useEffect(() => {
    if (props.hideSearchOnOverlay == true) {
      toggleSearchBox()
    }
  }, [props.hideSearchOnOverlay])

  useEffect(() => {
    if (isSearchOpened) {
      document.getElementById('main-search-input').focus()
    }
  }, [isSearchOpened])

  useEffect(() => {
    if (!isSearchOpened) {
      hideContentOverlay()
      enableBodyScroll()
      setSearchSuggestion(null)
      setAlternateSearchSuggestion(null)
      setIsSearchResult(false)
      setInputValue('')
      setEmptySearchResult(false)
    }
  }, [isSearchOpened])
  useOutsideAlerter(searchRef, setIsSearchOpened)

  // When mobile menu is not visible, remove tab navigation from anchor tags,
  // by giving them a tabIndex of -1. When mobile menu is displayed,
  // set the tabIndex to 0.
  return (
    <div id="header">
      <header className={`site-header container-xl ${bgOverlay ? 'bg-overlay' : ''}`}>
        {!props?.isMobile && (
          <div
            className={`site-header__top lg:px-6 xl:px-15 py-3 text-xs font-tertiary font-medium bg-gray-800 text-gray-400 ${
              bgOverlay ? 'bg-overlay-event' : ''
            }`}
          >
            <div className="flex items-center">
              <ul className="country-selector">
                <li className={`relative`}>
                  <CountrySelectorCurrent
                    show={showCountrySelector}
                    className="country-selector__current flex items-center cursor-pointer"
                    countryList={countryList}
                  />
                  <CountrySelectorOptions
                    show={showCountrySelector}
                    className="country-selector__options-pop absolute flex flex-row z-50 border-t border-gray-700"
                    countryList={countryList}
                    perChunk={7}
                    handleCountrySelectorModal={handleCountrySelectorModal}
                    isMobile={isMobile}
                  />
                </li>
              </ul>
            </div>
            <div>
              <ul className="flex uppercase text-xsm leading-16 font-normal">
                <li className="px-25 border-r border-white">
                  <span className="text-gray-400 pr-1">
                    {trans('call-us', 'Call us')} {trans('toll-free', 'toll-free')}
                  </span>
                  <a
                    href={`tel:${getRegionNumber()}`}
                    className="text-white hover:text-gwOrange"
                  >
                    {' '}
                    {getRegionNumber()}
                  </a>{' '}
                </li>
                {authenticateCheck ? (
                  <>
                    <li className="px-25 border-r border-white text-white hover:text-gwOrange focus:text-gwOrange">
                      <Link
                        href={`/account/dashboard`}
                        onClick={() => {
                          navClickGTM('/account/dashboard', 'My Account', 'Account', 'header')
                        }}
                      >
                        {trans('my-account', 'My Account')}
                      </Link>
                    </li>

                    <li className="pl-25">
                      <a
                        className="text-white hover:text-gwOrange focus:text-gwOrange"
                        href="#"
                        onClick={(e) => {
                          props.setShowOptions && props.setShowOptions(false)
                          e.preventDefault()
                          onSignOut()
                        }}
                      >
                        {trans('sign-out', 'SignOut')}
                      </a>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {!authenticateCheck ? (
                  <>
                    <li className="px-25 border-r border-white">
                      <Link
                        href="/sign-up"
                        className="text-white hover:text-gwOrange"
                        onClick={() => {
                          navClickGTM('/sign-up', 'Sign Up', 'Sign Up', 'header')
                        }}
                      >
                        {trans('sign-up', 'sign up')}
                      </Link>
                    </li>
                    <li className="pl-25 text-white hover:text-gwOrange">
                      <SignInLink text={trans('login', 'login')} />
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        )}
        {hellobar != null && <HelloBar {...hellobar.fields} />}
      </header>

      <ScrollableHeader
        searchRef={searchRefSticky}
        bgOverlay={bgOverlay}
        publicRuntimeConfig={publicRuntimeConfig}
        isSearchOpened={isSearchOpened}
        isAuthenticated={isAuthenticated}
        isOpened={isOpened}
        mobileMenuActive={mobileMenuActive}
        searchTerm={searchTerm}
        isSearchResult={isSearchResult}
        alternateSearchSuggestion={alternateSearchSuggestion}
        searchSuggestion={searchSuggestion}
        allList={allList}
        total_item={total_item}
        inputValue={inputValue}
        headerProps={props}
        searchData={searchData}
        searchInput={searchInput}
        searchInputField={searchInputField}
        emptySearchResult={emptySearchResult}
        topSellerData={topSellerData}
        popularSearches={popularSearches}
        showCountrySelector={showCountrySelector}
        handleMenuClick={handleMenuClick}
        setInputValue={setInputValue}
        openWishlistPage={openWishlistPage}
        triggerSearch={triggerSearch}
        setShowMegaMenu={setShowMegaMenu}
        toggleCloseSearch={toggleCloseSearch}
        clearText={clearText}
        setIsSearchOpened={setIsSearchOpened}
        clearAllData={clearAllData}
        setIsOpened={setIsOpened}
        setAlternateSearchSuggestion={setAlternateSearchSuggestion}
        setSearchSuggestion={setSearchSuggestion}
        setIsSearchResult={setIsSearchResult}
        toggleSearchBox={toggleSearchBox}
        setSearchTerm={setSearchTerm}
        enterPressed={enterPressed}
        handleSearchChange={handleSearchChange}
        closeMenu={closeMenu}
        headerClass={'topbar-placeholder-div header--sticky'}
        isMobile={isMobile}
        showMegaMenu={showMegaMenu}
      />
      <style jsx>
        {`
          @media only screen and (max-width: 453px) {
            .min-height-7 {
              min-height: 40px;
            }
          }

          #header {
            transition: all 0.15s linear;
            min-height: calc(100% - 700px);
          }

          .promo-text-line-height {
            line-height: 16.34px;
          }
          .promo-py-7 {
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }
        .promo-text-line-height {
          line-height: 16.34px;
        }
        .promo-py-7 {
          padding-top: 7px;
          padding-bottom: 7px;
        }

          .site-header {
            position: relative;
            transition: all 0.15s linear;
          }
          .bg-overlay:before {
            content: '';
            display: block;
            height: 178px;
            left: 0;
            opacity: 0.6;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 99;
            background-color: #1c1c1c;
            pointer-events: none;
          }
          .bg-overlay-event {
            pointer-events: none;
          }

        .site-navigation > li:hover a::after {
          background-color: #e36f22;
          bottom: 0px;
          content: '';
          display: block;
          height: 4px;
          position: absolute;
          width: 100%;
        }

        .mobile-menu-li {
          order: 1;
        }

        ul, ol {
          list-style: none;
        }
        `}
      </style>
    </div>
  )
}

export default Header

import getConfig from 'next/config'
import regions from '@/config/regions'
import { trans } from '@/lib/locale-helper'
import Icon from '@/components/icon/icon'
import Link from 'next/link'
import { getRegion, getRegionNumber } from '@/lib/region-helper'

const MobileSearchBox = (props) => {
  const { publicRuntimeConfig } = getConfig()
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  const defaultCountry = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

  let supportUrls = {
    en: 'https://support.gorewear.com/hc/en-us/requests/new',
    'en-gb': 'https://care.gorewear.com/hc/en-gb/requests/new',
    de: 'https://care.gorewear.com/hc/de/requests/new',
    es: 'https://care.gorewear.com/hc/es-es/requests/new',
    fr: 'https://care.gorewear.com/hc/fr/requests/new',
    it: 'https://care.gorewear.com/hc/it/requests/new',
  }

  const closeAndRedirect = (e, url) => {
    e.preventDefault()
    props.clearAllData(url)
  }

  let supportUrl = supportUrls[defaultCountry.localePrefix]

  if (defaultCountry.localePrefix == 'en' && defaultCountry.storeSource == 'EU') {
    supportUrl = supportUrls['en-gb']
  }

  return (
    <div className="mobile-search-box">
      <div className="px-6 md:mt-63 xxs:mt-33 md:h-full">
        <div className="flex items-center px-4 py-2.5 search-input-box relative border-black border">
          <Icon
            className={`cursor-pointer text-black fill-current w-5 h-5`}
            viewBox={`0 0 28 28`}
            size={28}
            icon="search"
            onClick={() => props.triggerSearch()}
          />
          <input
            type="text"
            ref={props.searchInput}
            value={props.searchTerm}
            id="main-search-input-mobile"
            name="search"
            placeholder="Search"
            aria-label="search"
            className="w-full ml-2 outline-none text-sm leading-18"
            onChange={(event) => props.setSearchTerm(event.target.value)}
            onKeyPress={(event) => {
              props.enterPressed(event)

              if (event?.key === 'Enter' || event?.keyCode === 13) {
                event?.target?.blur()
              }
            }}
          />
          {props.searchTerm && props.searchTerm.length > 1 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              onClick={() => props.clearText()}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.05806 0.558058C6.17527 0.440848 6.33424 0.375 6.5 0.375H15.6667C16.4721 0.375 17.125 1.02792 17.125 1.83333V10.1667C17.125 10.9721 16.4721 11.625 15.6667 11.625H6.5C6.33424 11.625 6.17527 11.5592 6.05806 11.4419L1.05806 6.44194C0.813981 6.19786 0.813981 5.80214 1.05806 5.55806L6.05806 0.558058ZM6.75888 1.625L2.38388 6L6.75888 10.375H15.6667C15.7817 10.375 15.875 10.2817 15.875 10.1667V1.83333C15.875 1.71827 15.7817 1.625 15.6667 1.625H6.75888ZM7.72493 3.05806C7.96901 2.81398 8.36473 2.81398 8.60881 3.05806L10.6668 5.11601L12.7247 3.05806C12.9688 2.81398 13.3645 2.81398 13.6086 3.05806C13.8527 3.30214 13.8527 3.69786 13.6086 3.94194L11.5507 5.9999L13.6088 8.05805C13.8529 8.30213 13.8529 8.69786 13.6088 8.94194C13.3647 9.18601 12.969 9.18601 12.7249 8.94194L10.6668 6.88378L8.60861 8.94194C8.36454 9.18601 7.96881 9.18601 7.72473 8.94194C7.48065 8.69786 7.48065 8.30213 7.72473 8.05805L9.78288 5.9999L7.72493 3.94194C7.48085 3.69786 7.48085 3.30214 7.72493 3.05806Z"
                fill="black"
              />
            </svg>
          )}
        </div>

        <div className="just-for-tab h-full">
          {!props.emptySearchResult && (
            <div className="search-lower md:inline-block w-full">
              <div className="tab-search-section-1 border-borderGray py-10 mr-2 inline-block h-full align-top">
                <p className="uppercase leading-16r tracking-tightest m-0 text-sm font-normal text-black">
                  {!props.isSearchResult
                    ? trans('popular-searches', 'Popular Searches')
                    : trans('search-results', 'Search Results')}
                </p>
                <ul className="mt-2 text-sm text-black list-none">
                  {!props.isSearchResult &&
                    props.popularSearches &&
                    props.popularSearches.map((item, key) => {
                      return (
                        <li className="leading-7" key={key}>
                          <Link
                            href={`/search?q=${item.searchQuery}`}
                            className="mt-2 text-sm text-black"
                          >
                            {item.searchQuery}
                          </Link>
                        </li>
                      )
                    })}
                  {props.isSearchResult &&
                    props.alternateSearchSuggestion &&
                    props.alternateSearchSuggestion.map((item, key) => {
                      return (
                        <li key={key}>
                          <Link href={`/search?q=${item.text}`} className="mt-2 text-sm text-black">
                            {item.text}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
                {props.isSearchResult && (
                  <div className="mt-5 text-sm text-black">
                    <Link href={`/search?q=${props.inputValue}`}>
                      Shop all "{props.inputValue}"
                    </Link>
                  </div>
                )}
              </div>
              <div className="mob-search-section visible md:hidden border-l border-borderGray"></div>
              <div className="tab-search-section-2 md:border-l md:border-borderGray md:py-10 inline-block h-full algin-top">
                <p className="uppercase m-0 text-black text-sm font-normal leading-16r tracking-tightest md:pl-10l">
                  {!props.isSearchResult
                    ? trans('top-sellers', 'Top Sellers')
                    : trans('product-results', 'Product Results')}
                </p>
                <div className="search-product-block mt-5">
                  {!props.isSearchResult &&
                    props.topSellerData &&
                    props.topSellerData.map((item, key) => {
                      return (
                        <a
                          key={key}
                          className="cursor-pointer"
                          onClick={(e) => closeAndRedirect(e, `${item.slug}`)}
                        >
                          <div className="search-box-product-card 2xs:pb-10 2xs:border-b md:pb-5 border-borderGray flex md:inline-flex w-full py-5 border-b md:pl-10l">
                            <img src={item.imageUrl} className="pc-image" />
                            <div className="product-info relative h-full  flex align-middle items-center">
                              <p className="text-sm leading-18 uppercase text-gray-600 absolute top-0">
                                <span className="leading-18 font-primary uppercase">
                                  {item.category.includes(' . ') ? (
                                    <>
                                      <span>
                                        {trans(
                                          item.category
                                            .split('.')[0]
                                            .trim()
                                            .replace(' ', '-')
                                            .toLowerCase(),
                                          item.category.split('.')[0].trim()
                                        )}
                                      </span>
                                      <span> · </span>
                                      <span>
                                        {trans(
                                          item.category
                                            .split('.')[1]
                                            .trim()
                                            .replace(' ', '-')
                                            .toLowerCase(),
                                          item.category.split('.')[1].trim()
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <span>
                                      {trans(
                                        item.category.replace(' ', '-').toLowerCase(),
                                        item.category
                                      )}
                                    </span>
                                  )}
                                </span>
                              </p>
                              <p className="text-base uppercase text-black leading-14">
                                {item.name}
                              </p>
                              <p className="text-base uppercase text-black leading-14 font-primary absolute bottom-0">
                                {country.currencySymbol}
                                {item.price}
                              </p>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                  {props.isSearchResult &&
                    props.searchData &&
                    props.searchData.map((item, key) => {
                      return (
                        <a
                          key={key}
                          className="cursor-pointer"
                          onClick={(e) => closeAndRedirect(e, `${item.slug}`)}
                        >
                          <div className="search-box-product-card py-5 flex md:inline-flex w-full border-b border-borderGray">
                            <img src={item.imageUrl} className="pc-image" />
                            <div className="product-info relative h-full flex align-middle items-center">
                              <p className="text-sm leading-18 uppercase text-gray-600 absolute top-0">
                                <span className="leading-18 font-primary uppercase">
                                  {item.category.includes(' . ') ? (
                                    <>
                                      <span>
                                        {trans(
                                          item.category
                                            .split('.')[0]
                                            .trim()
                                            .replace(' ', '-')
                                            .toLowerCase(),
                                          item.category.split('.')[0].trim()
                                        )}
                                      </span>
                                      <span> · </span>
                                      <span>
                                        {trans(
                                          item.category
                                            .split('.')[1]
                                            .trim()
                                            .replace(' ', '-')
                                            .toLowerCase(),
                                          item.category.split('.')[1].trim()
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <span>
                                      {trans(
                                        item.category.replace(' ', '-').toLowerCase(),
                                        item.category
                                      )}
                                    </span>
                                  )}
                                </span>
                              </p>
                              <p className="text-base uppercase text-black leading-14">
                                {item.name}
                              </p>
                              <p className="text-base uppercase text-black leading-14 font-primary absolute bottom-0">
                                {country.currencySymbol}
                                {item.price}
                              </p>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                </div>
              </div>
              <div className="tab-search-section-3 inline-block h-full border-l border-borderGray py-10 align-top"></div>
            </div>
          )}
        </div>
        <div className="just-for-mob">
          {!props.emptySearchResult && (
            <div className="search-lower md:flex w-full">
              <div className="relative">
                <div className="tab-search-section-1  border-borderGray pt-10 pb-22 mr-2.5 inline-block">
                  <p className="uppercase text-sm m-0 font-normal text-black leading-16r tracking-tightest">
                    {!props.isSearchResult
                      ? trans('popular-searches', 'Popular Searches')
                      : trans('search-results', 'Search Results')}
                  </p>
                  <ul className="mt-5 text-sm text-black leading-18 font-normal list-none">
                    {!props.isSearchResult &&
                      props.popularSearches &&
                      props.popularSearches.map((item, key) => {
                        return (
                          <li
                            className="mb-2"
                            key={key}
                            onClick={() => props.toggleSearchBox(true)}
                          >
                            <Link href={`/search?q=${item.searchQuery}`}>{item.searchQuery}</Link>
                          </li>
                        )
                      })}
                    {props.isSearchResult &&
                      props.alternateSearchSuggestion &&
                      props.alternateSearchSuggestion.map((item, key) => {
                        return (
                          <li
                            className="mb-2"
                            key={key}
                            onClick={() => props.toggleSearchBox(true)}
                          >
                            <Link href={`/search?q=${item.text}`}>{item.text}</Link>
                          </li>
                        )
                      })}
                  </ul>
                  {props.isSearchResult && (
                    <div
                      className="mt-3 text-sm text-black leading-18 font-normal"
                      onClick={() => props.toggleSearchBox(true)}
                    >
                      <Link href={`/search?q=${props.inputValue}`}>
                        Shop all "{props.inputValue}"
                      </Link>
                    </div>
                  )}
                </div>
                <div className="mob-search-section absolute visible md:hidden border-l border-borderGray inline-block h-full"></div>
              </div>
            </div>
          )}
        </div>
        {props.emptySearchResult && (
          <div className="search-lower just-for-tab md:px-4 md:flex w-full justify-center h-full">
            <div className="2xs:w-full md:w-1/2 empty-search-section-1 border-borderGray mr-2.5 pt-10">
              <div className="search-no-result w-full text-black font-normal">
                <p className="text-smd leading-16">
                  {trans('search-could-not-find', 'We’re sorry, we couldn’t find results for')} “
                  {props.inputValue}”.
                </p>
                <p className="mt-5 text-sm leading-18">{trans('search-hints', 'Search Hints:')}</p>
                <ul className="mt-4 list-disc ml-5 text-sm leading-18">
                  <li>{trans('search-hints-line-1', 'Double check the spelling')}</li>
                  <li>
                    {trans('search-hints-line-2', 'Use a more generic term (eg jacket, pants)')}
                  </li>
                  <li>
                    {trans('search-hints-line-3', 'Use the menu to find what you are looking for')}
                  </li>
                </ul>

                <p className="mt-4 text-sm leading-18">
                  {trans('try-popular-searches', 'Try one of our popular searches:')}
                </p>
                <ul className="mt-5 list-none">
                  {props.popularSearches &&
                    props.popularSearches.map((item, key) => {
                      return (
                        <li className="mb-3" key={key} onClick={() => props.toggleSearchBox(true)}>
                          <Link
                            href={`/search?q=${item.searchQuery}`}
                            className="text-sm leading-18"
                          >
                            {item.searchQuery}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
            <div className="2xs:w-full md:w-1/2 empty-search-section-2 md:border-l border-borderGray pt-10">
              <div className="search-no-result-contact w-full text-black text-center font-normal">
                <p className="text-md leading-18 tracking-tight">
                  {trans(
                    'search-couldnt-find-what-you-were-looking',
                    'Couldn’t Find What You Were Looking For?'
                  )}
                </p>
                <p className="mt-5 text-smd leading-18">
                  {trans('here-to-help', 'We’re always here to help.')}
                </p>
                <a
                  href={`tel:${getRegionNumber()}`}
                  className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-5 text-smd leading-18"
                >
                  {trans('call-us', 'Call us')}
                </a>
                <p className="mt-5 text-xsm leading-16 font-tertiary">{getRegionNumber()}</p>
                <p className="text-gray-600 text-xsm leading-16 uppercase font-tertiary">
                  {trans('seach-call-us-toll-free', 'Call us toll-free')},{' '}
                  {trans('search-mon-to-fri', 'MON-FRI 9AM - 6PM EST')}
                </p>
                <a
                  href={`mailto:${trans('customer-care-email', 'customercare@us.gorewear.com')}`}
                  className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-46 text-smd leading-18"
                >
                  {trans('email-us', 'Email us')}
                </a>
                <p className="mt-20.5 text-xsm leading-16 uppercase font-tertiary">
                  {trans('customer-care-email', 'customercare@us.gorewear.com')}
                </p>
                <p className="text-gray-600 text-xsm leading-16 uppercase font-tertiary">
                  {trans('responds-in-24-hours', 'Responds in 24 hours')}
                </p>

                <a
                  href={supportUrl}
                  className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-29.5 text-smd leading-18"
                >
                  {trans('search-contact-form', 'Contact Form')}
                </a>
                <p className="text-gray-600 mt-20.5 text-xsm leading-16 uppercase font-tertiary">
                  {trans('responds-in-24-hours', 'Responds in 24 hours')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {props.emptySearchResult && (
        <div className="search-lower just-for-mob md:px-4 pb-4 w-full justify-center">
          <div className="2xs:w-full px-6 md:w-1/2 border-b empty-search-section-1 md:border-r border-borderGray mr-2 py-10">
            <div className="search-no-result w-full text-black font-normal">
              <p className="text-17 leading-15">
                {trans('search-could-not-find', 'We’re sorry, we couldn’t find results for')} “
                {props.inputValue}”.
              </p>
              <p className="mt-5 text-sm leading-18">{trans('search-hints', 'Search Hints:')}</p>
              <ul className="mt-4 list-disc ml-5">
                <li className="text-sm leading-18">
                  {trans('search-hints-line-1', 'Double check the spelling')}
                </li>
                <li className="text-sm leading-18">
                  {trans('search-hints-line-2', 'Use a more generic term (eg jacket, pants)')}
                </li>
                <li className="text-sm leading-18">
                  {trans('search-hints-line-3', 'Use the menu to find what you are looking for')}
                </li>
              </ul>

              <p className="mt-4 text-sm leading-18">
                {trans('try-popular-searches', 'Try one of our popular searches:')}
              </p>
              <ul className="mt-5 list-none">
                {props.popularSearches &&
                  props.popularSearches.map((item, key) => {
                    return (
                      <li className="mb-3" key={key} onClick={() => props.toggleSearchBox(true)}>
                        <Link href={`/search?q=${item.searchQuery}`} className="text-sm leading-18">
                          {item.searchQuery}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
          <div className="2xs:w-full px-6 md:w-1/2 empty-search-section-2 md:border-l border-borderGray mr-2 py-10">
            <div className="search-no-result-contact w-full text-black text-center font-normal">
              <p className="text-smd leading-16">
                {trans(
                  'search-couldnt-find-what-you-were-looking',
                  'Couldn’t Find What You Were Looking For?'
                )}
              </p>
              <p className="mt-5 text-sm leading-18">
                {trans('here-to-help', 'We’re always here to help.')}
              </p>
              <a
                href={`tel:${getRegionNumber()}`}
                className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-5 text-smd leading-18"
              >
                {trans('call-us', 'Call us')}
              </a>
              <p className="mt-5 leading-16r text-xsm font-tertiary tracking-tightest">
                {getRegionNumber()}
              </p>
              <p className="text-gray-600 text-xsm leading-16r uppercase font-tertiary tracking-tightest">
                {trans('seach-call-us-toll-free', 'Call us toll-free')},{' '}
                {trans('search-mon-to-fri', 'MON-FRI 9AM - 6PM EST')}
              </p>
              <a
                href={`mailto:${trans('customer-care-email', 'customercare@us.gorewear.com')}`}
                className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-46 text-smd leading-18"
              >
                {trans('email-us', 'Email us')}
              </a>
              <p className="mt-20.5 text-xsm leading-16r uppercase font-tertiary tracking-tightest">
                {trans('customer-care-email', 'customercare@us.gorewear.com')}
              </p>
              <p className="text-gray-600 text-xsm leading-16r uppercase font-tertiary tracking-tightest">
                {trans('responds-in-24-hours', 'Responds in 24 hours')}
              </p>
              <a
                href={supportUrl}
                className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-29.5 text-smd leading-18"
              >
                {trans('search-contact-form', 'Contact Form')}
              </a>
              <p className="text-gray-600 mt-20.5 text-xsm leading-16r uppercase font-tertiary tracking-tightest">
                {trans('responds-in-24-hours', 'Responds in 24 hours')}
              </p>
            </div>
          </div>
        </div>
      )}
      {!props.emptySearchResult && (
        <div className="just-for-mob px-6 pt-5 pb-80 tab-search-section-2 2xs:border-t border-borderGray">
          <p className="uppercase text-sm m-0 font-normal text-black leading-16r tracking-tightest">
            {!props.isSearchResult
              ? trans('top-sellers', 'Top Sellers')
              : trans('product-results', 'Product Results')}
          </p>
          <div className="search-product-block mt-5">
            {!props.isSearchResult &&
              props.topSellerData &&
              props.topSellerData.map((item, key) => {
                return (
                  <a
                    key={key}
                    className="cursor-pointer"
                    onClick={(e) => closeAndRedirect(e, `${item.slug}`)}
                  >
                    <div className="search-box-product-card py-5 md:pb-2 2xs:border-b md:border-none border-borderGray flex md:inline-flex w-full p-2">
                      <img src={item.imageUrl} className="pc-image" />
                      <div className="product-info relative h-full flex align-middle items-center">
                        <p className="text-sm leading-18 uppercase text-gray-600 absolute top-0">
                          <span className="leading-18 font-primary uppercase">
                            {item.category.includes(' . ') ? (
                              <>
                                <span>
                                  {trans(
                                    item.category
                                      .split('.')[0]
                                      .trim()
                                      .replace(' ', '-')
                                      .toLowerCase(),
                                    item.category.split('.')[0].trim()
                                  )}
                                </span>
                                <span> · </span>
                                <span>
                                  {trans(
                                    item.category
                                      .split('.')[1]
                                      .trim()
                                      .replace(' ', '-')
                                      .toLowerCase(),
                                    item.category.split('.')[1].trim()
                                  )}
                                </span>
                              </>
                            ) : (
                              <span>
                                {trans(
                                  item.category.replace(' ', '-').toLowerCase(),
                                  item.category
                                )}
                              </span>
                            )}
                          </span>
                        </p>
                        <p className="text-base uppercase text-black leading-14">{item.name}</p>
                        <p className="text-base uppercase text-black leading-14 absolute bottom-0 font-primary">
                          {country.currencySymbol}
                          {item.price}
                        </p>
                      </div>
                    </div>
                  </a>
                )
              })}
            {props.isSearchResult &&
              props.searchData &&
              props.searchData.map((item, key) => {
                return (
                  <a
                    key={key}
                    className="cursor-pointer"
                    onClick={(e) => closeAndRedirect(e, `${item.slug}`)}
                  >
                    <div className="search-box-product-card py-5 md:pb-2 2xs:border-b md:border-none border-borderGray flex md:inline-flex w-full p-2">
                      <img src={item.imageUrl} className="pc-image" />
                      <div className="product-info relative h-full flex align-middle items-center">
                        <p className="text-sm leading-18 uppercase text-gray-600 absolute top-0">
                          <span className="leading-18 font-primary uppercase">
                            {item.category.includes(' . ') ? (
                              <>
                                <span>
                                  {trans(
                                    item.category
                                      .split('.')[0]
                                      .trim()
                                      .replace(' ', '-')
                                      .toLowerCase(),
                                    item.category.split('.')[0].trim()
                                  )}
                                </span>
                                <span> · </span>
                                <span>
                                  {trans(
                                    item.category
                                      .split('.')[1]
                                      .trim()
                                      .replace(' ', '-')
                                      .toLowerCase(),
                                    item.category.split('.')[1].trim()
                                  )}
                                </span>
                              </>
                            ) : (
                              <span>
                                {trans(
                                  item.category.replace(' ', '-').toLowerCase(),
                                  item.category
                                )}
                              </span>
                            )}
                          </span>
                        </p>
                        <p className="text-base uppercase text-black leading-14">{item.name}</p>
                        <p className="text-base uppercase text-black leading-14 absolute bottom-0 font-primary">
                          {country.currencySymbol}
                          {item.price}
                        </p>
                      </div>
                    </div>
                  </a>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileSearchBox
